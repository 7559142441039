import React from 'react';
import Layout from '../components/Shared/Layout';
import Career from '../components/career';
import '../assets/css/career.css';
import * as ReactGA from 'react-ga';

class CareerPage extends React.Component {
  componentDidMount() {
    ReactGA.set({
      title: 'Careers.Page',
    });
    ReactGA.pageview(this.props.location.pathname);

    const acc = document.getElementsByClassName('accordion');
    for (let i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function () {
        /* Toggle between adding and removing the "active" class,
            to highlight the button that controls the panel */
        this.classList.toggle('active');
        /* Toggle between hiding and showing the active panel */
        const panel = this.nextElementSibling;
        if (panel.style.display === 'block') {
          panel.style.display = 'none';
          panel.style.transition = '0.7s ease';
        } else {
          panel.style.display = 'block';
        }
      });
    }
  }

  render() {
    return (
      <Layout>
        <Career />
      </Layout>
    );
  }
}

export default CareerPage;
