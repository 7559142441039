import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import {useLocation} from '@reach/router';
import qs from 'query-string';

export default () => {
  const {search} = useLocation();
  const {q} = qs.parse(search);

  const [state, setState] = useState({
    zoho_listings: [],
    listings_fetched: false,
  });

  async function fetchListings() {
    const site = 'https://tintash.zohorecruit.com';
    const recruit = '/recruit/v2/public/Job_Openings?';
    const page_name = 'pagename=Tintash-Openings';
    const source = '&source=CareerSite';

    const url = site + recruit + page_name + source;
    const response = await fetch(url);
    const data = await response.json();
    setState({
      zoho_listings: data.data,
      listings_fetched: true,
    });
  }

  React.useEffect(() => {
    const acc = document.getElementById(q);
    const jobSec = document.getElementById('jobs-section');

    fetchListings();

    if (acc && jobSec) {
      acc.click();
      jobSec.scrollIntoView({behavior: 'smooth'});
    }
  }, [q]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Careers | Tintash</title>
      </Helmet>
      <div>
        <section className="career-banner w-100">
          <div className="layer mh-100">
            <div className="container">
              <div className="row d-flex justify-content-start align-items-end">
                <div className="col-12 mb-5">
                  <p className="heading">Careers</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whyus-section">
          <div className="container">
            <div className="row  justify-content-center align-items-center ">
              <div className="col-12 ">
                <h1 className="heading section-margin text-center text-md-left mb-0">
                  Join Our Team
                </h1>
                <p className="description description-margin mb-0">
                  Tintash is a human-centered organization and our big mission is bringing together,
                  inspiring and enabling great talent to create teams that love working together —
                  Teams that want to keep learning and increasing the bar to be the best versions of
                  themselves. These teams want to work on increasing important, complex, and
                  interesting problems that make the world a better place.
                </p>
                <h1 className="heading section-margin text-center text-md-left">
                  Why work with Us
                </h1>
                <div className="row description-margin">
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="box p-5 shadow-sm">
                      <div className="box-img">
                        <img
                          src={require('../assets/images/career/why-us/emblem.svg').default}
                          alt=""
                        />
                      </div>
                      <h3 className="box-title">Vision &amp; History</h3>
                      <p className="box-description">
                        Since 2008, our goal has been to become a preferred tech partner for
                        aspiring entrepreneurs, startups and growing companies looking to grow with
                        a remote team.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="box p-5 shadow-sm">
                      <div className="box-img">
                        <img
                          src={require('../assets/images/career/why-us/network.svg').default}
                          alt=""
                        />
                      </div>
                      <h3 className="box-title">Challenging Work and Learning Culture</h3>
                      <p className="box-description" style={{marginTop: '19px'}}>
                        We encourage individuals to solve problems, take risks and learn through
                        knowledge sharing.
                      </p>
                    </div>
                  </div>
                  {/* flexible routine */}
                  <div className="col-12 col-md-6 col-lg-4 ">
                    <div className="box p-5 shadow-sm">
                      <div className="box-img">
                        <img
                          src={require('../assets/images/career/why-us/stopwatch.svg').default}
                          alt=""
                        />
                      </div>
                      <h3 className="box-title">Flexible Routine</h3>
                      <p className="box-description">
                        We offer our team the comfort and flexibility of working remotely, and
                        occasionally from home, during the hours they feel most creative and
                        productive.
                      </p>
                    </div>
                  </div>
                  {/* flexible routine */}
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="box p-5 shadow-sm">
                      <div className="box-img">
                        <img
                          src={require('../assets/images/career/why-us/enterprise.svg').default}
                          alt=""
                        />
                      </div>
                      <h3 className="box-title">Upbeat Work Culture</h3>
                      <p className="box-description">
                        We believe in making work fun, and that is what you will find once you enter
                        our lair.
                      </p>
                    </div>
                  </div>
                  {/* flat Hierarchy */}
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="box p-5 shadow-sm">
                      <div className="box-img">
                        <img
                          src={require('../assets/images/career/why-us/diagram.svg').default}
                          alt=""
                        />
                      </div>
                      <h3 className="box-title">Flat Hierarchy</h3>
                      <p className="box-description">
                        We are here to make things happen and talk about ideas, solutions and
                        issues. A creative conflict is always encouraged here at Tintash.
                      </p>
                    </div>
                  </div>
                  {/* flat Hierarchy */}
                  <div className="col-12 col-md-6 col-lg-4 ">
                    <div className="box p-5 shadow-sm">
                      <div className="box-img">
                        <img
                          src={require('../assets/images/career/why-us/plant.svg').default}
                          alt=""
                        />
                      </div>
                      <h3 className="box-title">Growth</h3>
                      <p className="box-description">
                        You handle more responsibility, means you get to grow faster.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="team-section">
          <div className="container">
            <div className="row  justify-content-center align-items-center ">
              <div className="col-12 ">
                <h1 className="heading section-margin text-center text-md-left">
                  Things We Do Together
                </h1>
                <div className="row description-margin">
                  <div className="col-12 col-md-6 col-lg-4 ">
                    <div className="box w-100">
                      <img
                        className="w-100"
                        src={
                          require('../assets/images/career/team-activities/careers-eat.png').default
                        }
                        alt="careers-eat"
                      />
                      <div className="box-about">Eat</div>
                      <div className="overlay">
                        <div className="mh-100 h-100  overlay-text text-center">
                          <h3>Eat</h3>
                          <p>
                            We love food and great company! Together we enjoy meals, share ideas and
                            get to know one another better each day.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="box w-100">
                      <img
                        className="w-100"
                        src={
                          require('../assets/images/career/team-activities/careers-travel.jpg')
                            .default
                        }
                        alt="careers-travel"
                      />
                      <div className="box-about">Travel</div>
                      <div className="overlay">
                        <div className="mh-100 h-100  overlay-text text-center">
                          <h3>Traveling</h3>
                          <p>
                            Every year, we take a trip together to the mountains to forget about
                            work, unwind, cool off and have real fun.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="box w-100">
                      <img
                        className="w-100"
                        src={
                          require('../assets/images/career/team-activities/careers-sports.jpg')
                            .default
                        }
                        alt="careers-sports"
                      />
                      <div className="box-about">Sports</div>
                      <div className="overlay">
                        <div className="mh-100 h-100  overlay-text text-center">
                          <h3>Sports</h3>
                          <p>
                            We have regular fitness programs where our team indulges in some serious
                            football and cricket matches.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="box w-100">
                      <img
                        className="w-100 "
                        src={
                          require('../assets/images/career/team-activities/careers-movie.png')
                            .default
                        }
                        alt="careers-movie"
                      />
                      <div className="box-about">Movie Nights</div>
                      <div className="overlay">
                        <div className=" h-100  overlay-text text-center">
                          <h3>Movie Nights</h3>
                          <p>
                            The crazy film junkies in our team don’t let us miss a single great
                            movie!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="box w-100">
                      <img
                        className="w-100 "
                        src={
                          require('../assets/images/career/team-activities/careers-knowledge-training.jpg')
                            .default
                        }
                        alt="careers-knowledge-training"
                      />
                      <div className="box-about">Knowledge Training Sessions</div>
                      <div className="overlay d-flex">
                        <div className=" h-100 justify-content-center align-items-center  overlay-text text-center">
                          <h3>Knowledge Training Sessions</h3>
                          <p>
                            Whether it is a great book that someone read, a new technology or just a
                            great life experience, whatever we learn we share with the team.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="box w-100">
                      <img
                        className="w-100 "
                        src={
                          require('../assets/images/career/team-activities/careers-readings.jpg')
                            .default
                        }
                        alt="careers-readings"
                      />
                      <div className="box-about">Reading as a part of Learning</div>
                      <div className="view overlay">
                        <div className=" h-100  overlay-text text-center">
                          <h3>Reading as a part of Learning</h3>
                          <p>
                            Our very own Librarika holds all the books you will need for your
                            corporate and personal learning and development
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Leadership section Complete */}
        <section className="values-section py-5 section-margin">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-12 ">
                <h1 className="latoBlack text-center text-md-left heading">Our Values</h1>
                <div className="row description-margin">
                  <div className="col-lg-3 col-md-4 col-sm-12  bottom-margin">
                    <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                      <img
                        src={require('../assets/images/career/our-values/be-proactive.svg').default}
                        alt=""
                      />
                      <p className="text-center pt-3  values">Be Proactive</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-12  bottom-margin">
                    <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                      <img
                        src={
                          require('../assets/images/career/our-values/straight-talk.svg').default
                        }
                        alt=""
                      />
                      <p className="latoRegular text-right pt-3  values">Straight Talk</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-12  bottom-margin">
                    <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                      <img
                        src={
                          require('../assets/images/career/our-values/iterate-towards-excellence.svg')
                            .default
                        }
                        alt=""
                      />
                      <p className="text-center pt-3  values">Iterate Towards Excellence</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-12  bottom-margin">
                    <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                      <img
                        src={
                          require('../assets/images/career/our-values/spread-smiles.svg').default
                        }
                        alt=""
                      />
                      <p className="text-center pt-3  values">Spread Smiles</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-12 last-boxes">
                    <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                      <img
                        src={require('../assets/images/career/our-values/collaborate.svg').default}
                        alt=""
                      />
                      <p className=" text-center pt-3  values">Collaborate</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-12 last-boxes">
                    <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                      <img
                        src={require('../assets/images/career/our-values/data-driven.png').default}
                        alt=""
                      />
                      <p className=" text-center pt-3  values">Be Data Driven</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-12 last-boxes">
                    <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                      <img
                        src={require('../assets/images/career/our-values/prototype.png').default}
                        alt=""
                      />
                      <p className=" text-center pt-3  values">When in Doubt, Prototype</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-12 last-boxes">
                    <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                      <img
                        src={require('../assets/images/career/our-values/simple.png').default}
                        alt=""
                      />
                      <p className="text-center pt-3  values">
                        Transparency and Constructive Feedback
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="jobs-section" className="jobs-section">
          <div className="container">
            <div className="row justify-content-center align-items-center ">
              <div className="col-12">
                <h1 className="heading section-margin text-center text-md-left">
                  Current Openings
                </h1>
                <div className="jobs description-margin">
                  {state.listings_fetched ? (
                    state.zoho_listings.map((zh, index) => {
                      return (
                        <div className="zoho-listing" key={index}>
                          <a
                            target="_blank"
                            style={{textDecoration: 'none', color: '#5e656a'}}
                            href={zh.$url}
                          >
                            {zh.Job_Opening_Name}
                          </a>
                          <a
                            target="_blank"
                            style={{textDecoration: 'none', color: '#5e656a'}}
                            href={zh.$url}
                          >
                            +
                          </a>
                        </div>
                      );
                    })
                  ) : (
                    <div className="zoho-listing">
                      Please send your resume/CV at{' '}
                      <a href="mailto:careers@tintash.com">careers@tintash.com</a> and our team will
                      contact you soon.
                    </div>
                  )}

                  {/* <button id="fresh-grad" className="accordion mt-3">
                    <b>Fresh Graduates - 2021</b>
                  </button>
                  <div className="panel panel-default">
                    <div className="panel-heading ">Who Should Apply</div>
                    <div className="requirements">
                      <ul>
                        <li>
                          Computer Science and Software Engineering graduates for the year 2021
                        </li>
                      </ul>
                    </div>
                    <div className="panel-heading ">What We’re looking for</div>
                    <div className="requirements">
                      <ul>
                        <li>Strong Analytical and Problem Solving Skills</li>
                        <li>Good Grasp of Fundamental concepts of Computer Science</li>
                        <li>Can-do Attitude</li>
                        <li>Stellar Communication Skills</li>
                      </ul>
                    </div>
                    <div className="panel-heading ">How to Apply</div>
                    <p className="description mt-3">
                      Fill{' '}
                      <a
                        href="https://tintashfreshgraduates.paperform.co"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        this form
                      </a>{' '}
                      out to get started.
                    </p>
                    <p className="description">
                      Our People Operations team will get in touch with the shortlisted candidates
                      soon.
                    </p>
                  </div> */}

                  {/* <button id="reactjs-engineer" className="accordion mt-3">
                    <b>ReactJS Engineer</b>
                  </button>
                  <div className="panel panel-default">
                    <p className="description mt-4">
                      Tintash requires multiple ReactJS developers with at least 2+ years of
                      experience.
                    </p>
                    <div className="panel-heading mt-2">Responsibilities</div>
                    <div className="requirements">
                      <ul>
                        <li>Design and develop new user facing web applications with ReactJS</li>
                        <li>Translate designs and wireframes into high quality code</li>
                        <li>
                          Build reusable React components that can apply to a variety of use-cases
                          throughout our apps
                        </li>
                        <li>Write efficient, secure and readable code</li>
                        <li>Ensure best practices and processes for smooth development workflow</li>
                        <li>
                          Optimizing components for maximum performance across a vast array of
                          web-capable devices and browsers
                        </li>
                        <li>Collaborate, communicate and work well with co-workers and clients</li>
                      </ul>
                    </div>
                    <div className="panel-heading ">Skills and Qualifications</div>
                    <div className="requirements">
                      <ul>
                        <li>Good communication skills</li>
                        <li>
                          Strong proficiency in JavaScript, including DOM manipulation and the
                          JavaScript object model
                        </li>
                        <li>Experience with Redux</li>
                        <li>Thorough understanding of React.js and its core principles</li>
                        <li>Familiarity with the React Hooks API</li>
                        <li>Familiarity with newer specifications of EcmaScript</li>
                        <li>Strong Hands-on experience with TypeScript</li>
                        <li>Familiarity with RESTful APIs</li>
                        <li>
                          Knowledge of modern authorization mechanisms, such as JSON Web Token
                        </li>
                        <li>
                          Experience with common front-end development tools such as Babel, Webpack,
                          NPM, etc
                        </li>
                        <li>A knack for benchmarking and optimization</li>
                        <li>
                          Able to develop responsive applications and design mobile-based features
                        </li>
                        <li>Experience with Git</li>
                      </ul>
                    </div>
                    <div className="panel-heading ">Good to haves</div>
                    <div className="requirements">
                      <ul>
                        <li>Familiarity with GraphQL</li>
                        <li>Experience in test cases (JS)</li>
                        <li>Experience in other frontend frameworks like (Angular / Vue)</li>
                      </ul>
                    </div>
                    <div className="panel-heading ">How to Apply</div>
                    <p className="description mt-3">
                      Fill out{' '}
                      <a
                        href="https://careerstintashsignup.paperform.co/?Listing=ReactJS%20Engineer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        this form
                      </a>{' '}
                      to get started, and our People Operations team will be in touch with the
                      shortlisted candidates soon.
                    </p>
                  </div>

                  <button id="fullStack-js-engineer" className="accordion mt-3">
                    <b>FullStack Javascript Engineer</b>
                  </button>
                  <div className="panel panel-default">
                    <p className="description mt-4">
                      We are looking for a high performing software engineer to join our team. Our
                      ideal candidate must be able to tackle the daily challenges of a fast paced
                      startup environment while leading design and development of web applications.
                      This lead-level role includes a primary function of frontend development and a
                      secondary function of supporting our backend development team.
                    </p>
                    <div className="panel-heading mt-2">Requirements</div>
                    <div className="requirements">
                      <ul>
                        <li>
                          Take on a lead role in the design and development of web applications used
                          by our customers daily
                        </li>
                        <li>
                          Develop and maintain key aspects of the technology base for our web
                          application
                        </li>
                        <li>Excellent written and verbal communication skills</li>
                        <li>Have a positive attitude towards and an eagerness for learning</li>
                        <li>
                          Advocate for modern agile software development practices that deliver high
                          quality code: continuous integration, test, deployment practices and tool
                          sets
                        </li>
                        <li>Healthcare software development experience a plus</li>
                        <li>Turning client requirements into elegant user centric designs</li>
                        <li>
                          Work closely with other developers and product owners in an agile team
                        </li>
                        <li>
                          Able to analyze, troubleshoot and solve problems includes Identifying and
                          fixing performance bottlenecks and bugs
                        </li>
                      </ul>
                    </div>
                    <div className="panel-heading ">Qualifications &amp; Skills</div>
                    <div className="requirements">
                      <ul>
                        <li>3+ years of relevant work experience</li>
                        <li>Good Expertise in building distributed highly scalable solutions</li>
                        <li>
                          Proven ability to learn and adapt to new, complex development environments
                          with deep dive analytical skills
                        </li>
                        <li>Expert in React, JavaScript (TypeScript a plus)</li>
                        <li>Expert in Jest, Mocha or Similar React Testing frameworks</li>
                        <li>
                          In-depth knowledge of Node.js and experience with designing robust &amp;
                          scalable backend architectures.
                        </li>
                        <li>Expert in implementing REST APIs</li>
                        <li>Proficient with UI Frameworks including Material UI</li>
                        <li>Proficient with Git/Version control systems</li>
                        <li>Proficient with BitBucket, Jira, Confluence</li>
                        <li>Experience with OAuth authentication and session management</li>
                        <li>
                          Experience with AWS services (RDS, DynamoDB, S3, Lambda, API Gateway,
                          Cognito)
                        </li>
                        <li>Dedicated to producing quality code through best practices</li>
                        <li>Understanding of asynchronous programming and its quirks</li>
                      </ul>
                    </div>
                    <div className="panel-heading ">Good to haves</div>
                    <div className="requirements">
                      <ul>
                        <li>Expertise in continuous integration and deployment (CI/CD)</li>
                        <li>
                          Experience with building or contributing to HIPAA-compliant products
                        </li>
                        <li>Experience with Docker/Kubernetes</li>
                      </ul>
                    </div>
                    <div className="panel-heading ">How to Apply</div>
                    <p className="description mt-3">
                      Fill out{' '}
                      <a
                        href="https://careerstintashsignup.paperform.co/?Listing=FullStack%20Javascript%20Engineer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        this form
                      </a>{' '}
                      to get started, and our People Operations team will be in touch with the
                      shortlisted candidates soon.
                    </p>
                  </div>

                  <button id="python-engineer" className="accordion mt-3">
                    <b>Python Engineer</b>
                  </button>
                  <div className="panel panel-default">
                    <p className="description mt-4">
                      Tintash is looking for Mid to Senior level Python engineers with expertise and
                      experience in designing, developing, implementing and scaling web applications
                      for our rapidly growing Web team.
                    </p>
                    <div className="panel-heading mt-2">Responsibilities</div>
                    <div className="requirements">
                      <ul>
                        <li>Write efficient, testable, and reusable code</li>
                        <li>Solve complex performance problems and architectural challenges</li>
                        <li>
                          Work closely with other developers and product owners in an agile team
                        </li>
                        <li>Perform code reviews and mentor your peers</li>
                        <li>Turn client requirements into elegant user centric designs</li>
                        <li>
                          Build new products and features through rapid build/measure/learn
                          iterations.
                        </li>
                        <li>Ensure best practices and processes for smooth development workflow</li>
                        <li>Ability to analyze, troubleshoot and solve problems.</li>
                        <li>Implement security and data protection solutions</li>
                        <li>
                          Communicate directly with clients to provide desired information and
                          status on technical inquiries.
                        </li>
                        <li>
                          Coordinate with internal teams to understand user requirements and provide
                          technical solutions
                        </li>
                      </ul>
                    </div>
                    <div className="panel-heading ">Skills and Qualifications</div>
                    <div className="requirements">
                      <ul>
                        <li>Good communication skills</li>
                        <li>At least 3 years of development experience</li>
                        <li>
                          Understanding of fundamental design principles behind a scalable
                          application
                        </li>
                        <li>
                          Understanding of the threading limitations of Python, and multi-process
                          architecture
                        </li>
                        <li>
                          In-depth knowledge with hands-on experience of Python and frameworks
                          available for it such as Django, Flask.
                        </li>
                        <li>Good working knowledge of relational and non-relational databases</li>
                        <li>
                          Good exposure in writing and optimizing SQL for high-performance systems
                          with large databases
                        </li>
                        <li>
                          Experience working on scalable, high availability applications / services
                        </li>
                        <li>Familiarity with microservices, Restful APIs, and web sockets</li>
                        <li>
                          Understanding of security threats and “security by design” principles
                        </li>
                        <li>Experience with Message Queues (Pub/Sub) / Redis / ElasticSearch</li>
                        <li>Experience with Git</li>
                        <li>
                          Familiarity with the Amazon/Google cloud services, Deploying, testing and
                          maintaining large scale production infrastructure on AWS or GCP
                        </li>
                      </ul>
                    </div>
                    <div className="panel-heading ">Good to haves</div>
                    <div className="requirements">
                      <ul>
                        <li>Experience with Docker/Kubernetes</li>
                        <li>
                          Experience in server-side templating languages (like Jinja 2, Mako, etc.)
                        </li>
                        <li>Knowledge in Amazon serverless architecture preferred</li>
                      </ul>
                    </div>
                    <div className="panel-heading ">How to Apply</div>
                    <p className="description mt-3">
                      Fill out{' '}
                      <a
                        href="https://careerstintashsignup.paperform.co/?Listing=Python%20Engineer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        this form
                      </a>{' '}
                      to get started, and our People Operations team will be in touch with the
                      shortlisted candidates soon.
                    </p>
                  </div>

                  <button id="blockchain-api-engineer" className="accordion mt-3">
                    <b>Blockchain API Engineer</b>
                  </button>
                  <div className="panel panel-default">
                    <div className="panel-heading my-4">Overview</div>
                    <div className="requirements">
                      <ol>
                        <li>
                          Learn Blockchain and build APIs for different Blockchains which will be
                          used by thousands of engineers.
                        </li>
                        <li>
                          Work in an open source environment with the top team in the blockchain
                          industry to help build, monitor, scale and maintain a cutting edge
                          blockchain anchored on bitcoin.
                        </li>
                        <li>
                          Think out of the box and work with people expanding the boundaries of the
                          blockchain and its applications.
                        </li>
                        <li>
                          Implement solutions to problems faced by the community and write Technical
                          Documentation to help increase the adoption of the platform.
                        </li>
                        <li>
                          Experience communicating with technical teams in a remote setting to
                          collect requirements, describe features, designs and product strategy.
                        </li>
                        <li>
                          Utilise best practices in code collaboration and deployment and generate
                          and review small incremental PRs.
                        </li>
                        <li>
                          Connect the frontend applications with smart contracts and call functions
                          in smart contracts from the client apps.
                        </li>
                      </ol>
                    </div>
                    <div className="panel-heading">Technical Requirements</div>
                    <div className="requirements">
                      <ul>
                        <li>Proficiency in GO/Typescript. Rust and Clarity are a plus</li>
                        <li>
                          General knowledge and understanding of basic concepts and techs used in
                          blockchain technology is a plus
                        </li>
                        <li>Strong object-oriented design and development experience</li>
                        <li>Expert knowledge of algorithms, data structures, math</li>
                        <li>
                          Ability to drive technical excellence, pushing innovation and quality
                        </li>
                        <li>
                          Demonstrated ability to coach, mentor and increase the skill level of
                          teammates
                        </li>
                        <li>
                          Experience with REST web services, message brokers, network programming
                        </li>
                      </ul>
                    </div>
                    <div className="panel-heading">Other Skills</div>
                    <div className="requirements">
                      <ul>
                        <li>Self-driven, independent, and responsible</li>
                        <li>Proficient in English (written and spoken)</li>
                        <li>Work with the team to help drive business strategy and decisions</li>
                        <li>Comfortable in an agile development environment</li>
                      </ul>
                    </div>
                    <div className="panel-heading ">How to Apply</div>
                    <p className="description mt-3">
                      Fill out{' '}
                      <a
                        href="https://careerstintashsignup.paperform.co/?Listing=Blockchain%20API%20Engineer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        this form
                      </a>{' '}
                      to get started, and our People Operations team will be in touch with the
                      shortlisted candidates soon.
                    </p>
                  </div>
                  <button id="csharp-engineer" className="accordion mt-3">
                    <b>C# / .NET Engineer</b>
                  </button>
                  <div className="panel panel-default">
                    <div className="panel-heading my-4">Job description</div>
                    <p className="description mt-4">
                      Tintash is looking to hire a contract worker for the web team. It is a remote
                      contractual job for a 2-3 weeks project but can extend further as well. This
                      position can lead to a full time role depending upon your performance. As a
                      Backend Engineer you will design, implement and maintain a secure and tested
                      application, frequently liaising with other teams across multiple disciplines.
                    </p>
                    <div className="panel-heading">Responsibilities</div>
                    <div className="requirements">
                      <ul>
                        <li>
                          Work closely with colleagues across Product Engineering to deliver
                          high-quality software that provides direct value to farmers and
                          enterprises.
                        </li>
                        <li>
                          Collaborate with the Data Science team by providing them with quality data
                          and delivering their models’ output to customers via application services.
                        </li>
                        <li>
                          As part of the team you will contribute to the why (roadmap), what (story
                          review), how (detailed design and architecture) and when (work estimation,
                          deadlines etc.) we build the product plans.
                        </li>
                        <li>
                          Level-up developers via design discussions, code reviews and pair
                          programming, fostering an environment of collaboration and innovation.
                        </li>
                        <li>
                          Provide Tier 3 support (identify, troubleshoot, fix and workaround) for
                          applications and services – you own your code!
                        </li>
                        <li>
                          Write applications that are modular, well tested and easy to maintain.
                        </li>
                        <li>
                          Develop, deploy and maintain back-end services and APIs, for internal or
                          external use.
                        </li>
                      </ul>
                    </div>
                    <div className="panel-heading">Requirements</div>
                    <p className="description mt-2">
                      If you are a skilled coder and a fast learner who thrives in challenging
                      environments and has a creative yet pragmatic approach to problem-solving,
                      read on!
                    </p>
                    <div className="requirements">
                      <ul>
                        <li>
                          Bachelor’s degree (or higher) in Computer Science or a related field. And
                          4+ years of experience in software engineering, including:
                          <ul>
                            <li>
                              3+ years of back end / web service frameworks (C# / .NET preferred)
                            </li>
                            <li>
                              Design and use of RESTful APIs and Cloud-based microservices
                              leveraging queues and serverless functions with state caching (Azure
                              preferred)
                            </li>
                            <li>
                              Microservice principles of Service Discovery, CQRS/Event Sourcing,
                              Distributed Tracing, and Service Integration Testing
                            </li>
                            <li>Relational and non-relational databases (SQL Server, MongoDB)</li>
                            <li>MVC and ORM paradigms (EntityFramework desirable)</li>
                            <li>Modern CI/CD practices applied to complex multi-repo code bases</li>
                          </ul>
                        </li>
                        <li>
                          Ability to orchestrate service calls and data flow across internal
                          microservices while optimizing a lambda architecture for high performance
                          and reliability
                        </li>
                        <li>
                          Team player who challenges assumptions, identifies blind spots, and
                          constantly levels up their craft
                        </li>
                        <li>
                          Resourceful, proactive, and constructive when faced with incomplete
                          requirements and multiple priorities
                        </li>
                        <li>
                          Maturity to interact directly with partners, own challenging assignments
                          and take accountability for the team’s success
                        </li>
                      </ul>
                    </div>

                    <div className="panel-heading ">How to Apply</div>
                    <p className="description mt-3">
                      Fill out{' '}
                      <a
                        href="https://careerstintashsignup.paperform.co/?Listing=C%23%20%2F%20.NET%20Engineer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        this form
                      </a>{' '}
                      to get started, and our People Operations team will be in touch with the
                      shortlisted candidates soon.
                    </p>
                  </div>
                  <button id="sales-professional" className="accordion mt-3">
                    <b>Sales Professional</b>
                  </button>
                  <div className="panel panel-default">
                    <div className="panel-heading my-4">Job Requirements</div>
                    <p className="description mt-4">
                      Tintash is looking for a deeply motivated individual to join its sales team.
                      The person hired on this position will report directly to the Head of sales
                      team. Below are details of the requirements:
                    </p>
                    <p className="bold-description mt-4">
                      Below are qualities and attributes that the candidates should either be able
                      to demonstrate at the time of hiring or should be able to demonstrate that
                      they have potential to develop them within 3 months after hiring
                    </p>
                    <div className="requirements">
                      <ul>
                        <li>
                          Be a great cultural fit for the sales team in particular and for Tintash
                          in general.
                        </li>
                        <li>
                          Helping each other overcome challenges is an integral part of our ethos,
                          and we want new members of the team to be team players. If you’re a team
                          player, are always keen on exploring new ways and synergies to work
                          together, and can contribute as an individual while always considering the
                          collective good of the team and the company, then you’re a good fit.
                          However, if you’re a solo player, then this opening isn’t the right one
                          for you.
                        </li>
                        <li>
                          Be able to communicate exceptionally well in English, in both verbal and
                          written forms.
                        </li>
                        <li>
                          The candidates should have the ability to build and cultivate profitable
                          relationships with potential and existing clients to develop and grow the
                          sales pipeline to consistently meet quarterly revenue goals, and
                          proactively contribute towards converting leads by playing a leadership
                          role in tasks such as: Requirement gathering, Estimates & Proposals
                          writing, and Contract finalization
                        </li>
                        <li>
                          Be able to develop a very good understanding of how the software products
                          are produced and use that understanding to communicate with clients and
                          technical teams
                        </li>
                        <li>
                          Develop an understanding of the overall delivery lifecycle of a project
                          and apply that understanding while working with clients and
                          delivery/technical teams
                        </li>
                        <li>
                          Constantly upgrade their skills and knowledge areas to adapt to the
                          ever-evolving tech landscape in order to effectively communicate with
                          clients and other team members.
                        </li>
                      </ul>
                    </div>

                    <p className="bold-description mt-4">
                      Below are responsibilities that the candidate should be able to take on as a
                      part of the role. The candidates should either be able to demonstrate their
                      ability to handle these responsibilities at the time of hiring or they should
                      be able to demonstrate potential to take on all of these responsibilities
                      within 6 to 12 months after joining.
                    </p>
                    <div className="requirements">
                      <ul>
                        <li>
                          Keeping an eye out for new leads that come through the company’s lead
                          generation system and proactively pick up responsibility for those leads
                        </li>
                        <li>
                          Finding as much information as possible related to the client’s personal
                          profile, their team profile, the client’s company profile, and the project
                          they expect Tintash to work on
                        </li>
                        <li>
                          Using the aforementioned information to proactively contribute towards
                          client qualification discussions and decisions
                        </li>
                        <li>Responding to emails from clients on time</li>
                        <li>
                          Proactively coordinating with internal stakeholders and the client to
                          schedule introductory calls
                        </li>
                        <li>
                          Collecting as much information from the client related to the project they
                          want delivered before the introductory call, and going through the
                          information to pick up important and critical information related to
                          delivery
                        </li>
                        <li>
                          Working with internal stakeholders to prepare for introductory calls by
                          highlighting important and critical information related to the project for
                          them, collecting notes, and ensuring that there is a clear plan in place
                          for the call where everyone understands what they will need to do on the
                          call
                        </li>
                        <li>
                          Leading of the introductory call to present Tintash as the best possible
                          vendor for the client. Defining the overall sequence of steps related to
                          the overall proposal process that the client can expect.
                        </li>
                        <li>
                          Working with the client to get further information needed for drafting a
                          proposal and ensuring that the development team fully understands the
                          needs of the client. Also getting clarity from the client on any areas
                          where the development team is uncertain about project requirements.
                        </li>
                        <li>Drafting the proposal for the client.</li>
                        <li>
                          Scheduling a proposal walkthrough meeting and taking a lead in presenting
                          the proposal to convert the project.
                        </li>
                        <li>
                          Negotiating the terms of the engagement model with the client, including
                          pricing and scope etc
                        </li>
                        <li>
                          Finalization of contract by working with the legal team and signing the
                          contract
                        </li>
                        <li>
                          Participating in post conversion activities to get client feedback, and
                          working with the development team to make improvements as per client
                          feedback.
                        </li>
                        <li>
                          Working with the client to explore opportunities for selling further
                          Tintash services.
                        </li>
                      </ul>
                    </div>
                    <div className="panel-heading my-4">Additional Requirements:</div>
                    <div className="requirements">
                      <ul>
                        <li>
                          We believe in skills rather than degrees. Ideally, we’re looking for
                          someone who has a Bachelor’s or Master’s Degree in Engineering (a degree
                          in Computer Science would be a plus). However, we welcome applications
                          from candidates from other fields who feel they’re a good fit.
                        </li>
                        <li>
                          1-3 years experience in a position which required excellent English
                          Communication (spoken and written) skills.
                        </li>
                        <li>
                          Demonstrated ability to meet and/or exceed determined sales objectives.{' '}
                        </li>
                        <li>
                          Demonstrated skills in proposal writing, sales pitches and contract
                          writing and negotiations.{' '}
                        </li>
                        <li>
                          It is preferred if the candidate has experience in services based industry
                          and understands the difference in sales approaches between product and
                          service based sales.
                        </li>
                        <li>
                          Comfortable with flexible timings and dealing with international clients
                          in different time zones.
                        </li>
                        <li>
                          It is preferable if the candidate has some prior experience with
                          collaborating/coordinating on engineering related projects.
                        </li>
                        <li>
                          Comfortable in using CRM, project management tools and GSuite/Office
                          Suite.
                        </li>
                      </ul>
                    </div>

                    <div className="panel-heading ">How to Apply</div>
                    <p className="description mt-3">
                      Fill out{' '}
                      <a
                        href="https://careerstintashsignup.paperform.co/?Listing=Sales%20Professional"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        this form
                      </a>{' '}
                      to get started, and our People Operations team will be in touch with the
                      shortlisted candidates soon.
                    </p>
                  </div>
                  <button id="lead-blockchain" className="accordion mt-3">
                    <b>Technical Lead/Lead Developer - Blockchain</b>
                  </button>
                  <div className="panel panel-default">
                    <p className="description mt-4">
                      Tintash is looking for a <b>Technical Lead/Lead Developer</b> who will join
                      our Blockchain Team and help us build new features and applications for our
                      enterprise clients.
                    </p>
                    <div className="panel-heading my-4">Responsibilities:</div>
                    <div className="requirements">
                      <ul>
                        <li>
                          Build strong, resilient, and robust APIs, libraries, and services to power
                          our wallet services: hot and cold wallets, indexing, staking, and SDK
                          services
                        </li>
                        <li>
                          Own functionality and scalability features by taking responsibility from
                          inception to deployment and customer integration
                        </li>
                        <li>
                          Expose and document functionality to the UI, third-party developers, and
                          our internal and external tooling
                        </li>
                        <li>
                          Collaborate closely with all engineering teams to ensure consistency in
                          understanding of technical requirements and overall work quality
                        </li>
                        <li>
                          Provide mentorship to your peers in the broader engineering team through
                          code reviews and education on best practices
                        </li>
                      </ul>
                    </div>
                    <div className="panel-heading my-4">Skills & Experience:</div>
                    <p className="description mt-4">
                      We are looking for teammates who share and practice our values: open
                      communication, transparency, taking ownership, and a high level of
                      craftsmanship. We are looking for coworkers who share our vision and mission:
                      deliver trust in digital assets.
                    </p>
                    <div className="panel-heading my-4">Required</div>
                    <div className="requirements">
                      <ul>
                        <li>At least 5 years of back-end, server-side software development</li>
                        <li>Strong experience in microservices architecture</li>
                        <li>
                          Strong experience with Node.JS, JavaScript, GO, Ruby and Typescript or
                          Java
                        </li>
                        <li>Strong experience with RESTful API design</li>
                        <li>Strong experience with message brokers like Kafka or RabbitMQ</li>
                        <li>Experience in MongoDB</li>
                        <li>
                          Experience in Git/GitHub and branching methodologies, code review tools,
                          CI tools, JIRA, Confluence, etc.
                        </li>
                        <li>Great with unit and functional testing and debugging</li>
                        <li>
                          Bachelor’s degree in computer science, applied mathematics, or another
                          technical discipline from a top university
                        </li>
                        <li>Great at algorithm design, problem solving, and complexity analysis</li>
                      </ul>
                    </div>
                    <div className="panel-heading my-4">Preferred</div>
                    <div className="requirements">
                      <ul>
                        <li>Experience splitting a monolith into microservices</li>
                        <li>Strong experience in Bitcoin, Ethereum</li>
                        <li>
                          Understanding and strong interest in cryptocurrencies and blockchain
                          industry
                        </li>
                        <li>Understanding and experience working in the financial industry</li>
                        <li>
                          Familiarity with any of the following: API and SDK services, digital
                          wallets, clearing and settlement, lending, etc.
                        </li>
                        <li>Familiarity with Kubernetes, Docker</li>
                        <li>
                          Experience with large-scale, real-time, and distributed applications
                        </li>
                      </ul>
                    </div>
                    <div className="panel-heading ">How to Apply</div>
                    <p className="description mt-3">
                      Fill out{' '}
                      <a
                        href={
                          'https://careerstintashsignup.paperform.co/' +
                          `?Listing=${encodeURIComponent(
                            'Technical Lead/Lead Developer - Blockchain'
                          )}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        this form
                      </a>{' '}
                      to get started, and our People Operations team will be in touch with the
                      shortlisted candidates soon.
                    </p>
                  </div>

                  <button id="business-development-associate" className="accordion mt-3">
                    <b>Business Development Associate - Inbound Marketing</b>
                  </button>
                  <div className="panel panel-default">
                    <p className="description mt-4">
                      You’ll play a key role in generating new incoming business for Tintash, with a
                      primary focus on search engine marketing. You’ll be responsible for designing
                      and implementing campaigns across different service areas, continually
                      optimizing them after launch to meet KPI targets for each quarter. Reporting
                      to the Project Manager for Inbound Marketing, you’ll be working closely with
                      the lead generation team, often wearing multiple hats where required. This
                      role is critical to the organization, ensuring that the sales pipeline remains
                      saturated with qualified leads throughout.
                    </p>
                    <div className="panel-heading mt-2">What You'll Do</div>
                    <div className="requirements">
                      <ul>
                        <li>
                          Design, Launch, and Optimize SEM campaigns (mainly on Google Ads) to
                          reliably generate new business across various service areas.
                        </li>
                        <li>
                          Perform market research related to Inbound Lead Generation, monitoring
                          continually evolving keyword and audience interest trends within the
                          industry.
                        </li>
                        <li>
                          Reviewing analytics and user behaviour trends to extract actionable
                          insights for lead generation efforts.
                        </li>
                        <li>
                          Implementing and maintaining integrations to pass key data between Google
                          Ads, the company website, and our current CRM.
                        </li>
                      </ul>
                      <p className="description mt-4">
                        You’ll be working closely with the amazing people within the business
                        development team at Tintash and along with the primary responsibility of
                        lead generation, you’ll be collaborating with multiple teams, allowing you
                        to build a versatile skill set across different functional areas.
                      </p>
                    </div>
                    <div className="panel-heading ">Your Skills and Traits</div>
                    <div className="requirements">
                      <ul>
                        <li>
                          Some experience with analytics software such as google analytics and
                          hotjar, with the ability to identify trends and actionable insights from
                          the data.
                        </li>
                        <li>
                          Strong understanding of the digital marketing toolkit, including platforms
                          (especially Google Ads) and research tools (SemRush, SpyFu, etc.)
                        </li>
                        <li>
                          Experience with CRM software (e.g. Salesforce,Pipedrive,Hubspot, etc)
                        </li>
                        <li>Familiarity with MS Excel (analyzing spreadsheets and charts)</li>
                        <li>Understanding of lead generation performance metrics</li>
                        <li>Excellent communication and negotiation skills</li>
                        <li>
                          (Preferred) Experience with system design and mapping out core business
                          processes to digital systems, including insight into implementation
                          strategies.
                        </li>
                        <li>
                          (Preferred) Ability to approach problems from both a technical and busses
                          perspective, taking both aspects into consideration for any proposed
                          solution
                        </li>
                      </ul>
                    </div>
                    <div className="panel-heading ">How to Apply</div>
                    <p className="description mt-3">
                      Fill out{' '}
                      <a
                        href="https://careerstintashsignup.paperform.co/?Listing=Sales%20Associate"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        this form
                      </a>{' '}
                      to get started, and our People Operations team will be in touch with the
                      shortlisted candidates soon.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Place this exact code where you want the Jobscore job widget to appear on the page. The widget will expand to the size of the containing div */}
        {/* <section class="jobs-section">
    <div class="container">
        <div class="row  justify-content-center align-items-center">
            <div class="col-12">
                <h1 class="heading section-margin">Current Openings</h1>
                <div class='jobscore-jobs' data-group-by='department' >
                    <div class='js-fallback' style='color:#888'>
                        <a style='color:#888;text-decoration:underline;' href='https://careers.jobscore.com/jobs/tintashpvtltd'>Tintash Pvt Ltd jobs
                        </a> powered by
                        <a style='color:#888' href='https://www.jobscore.com/'>
                            <img style='padding:0;' align='bottom' src='https://careers.jobscore.com/images/new/jobscore_logo_embed.png' alt='Applicant Tracking, Social Recruiting and Software Hiring Solution - JobScore'>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> */}
        {/* /jobscore-jobs */}
        <section className="career-footer">
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-12">
                <h1 className="heading">We’re always keen to meet great people</h1>
                {/* 				<p class="description">
                    Even if we do not have a current opening for you, let us know the areas where you might fit in.
                </p>
 */}{' '}
                <p className="description">
                  Drop your résumé at{' '}
                  <a href="mailto:careers@tintash.com" className="resumes">
                    careers@tintash.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
